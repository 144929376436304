<template>
  <div class="overflow-hidden" style="padding: 0px;">
    <div style="padding: 12px">
      <div style="text-align:center;" v-if="loading">
        <b-spinner large></b-spinner>
      </div>
        <b-row v-if="!loading" class="mb-5">
          <b-col cols="12" class="d-flex tmpt-lb">
            <section>
            <img
            v-if="dataDetail.dataOrmas.file_logo !== '' && dataDetail.dataOrmas.file_logo !== null"
              :src="fileURL + '/' + dataDetail.dataOrmas.file_logo"
              style="width: 80px; height: 80px; margin-bottom: 20px; margin-left: 8px"
              alt="abstract"
            />
            <img
            v-else
              src="http://pesmcopt.com/admin-media/images/default-logo.png"
              style="width: 80px; height: 80px; margin-bottom: 20px; margin-left: 8px"
              alt="abstract"
            />
            </section>
            <section>
            <img
            v-if="dataDetail.dataOrmas.file_bendera !== '' && dataDetail.dataOrmas.file_bendera !== null"
              :src="fileURL + '/' + dataDetail.dataOrmas.file_bendera"
              style="width: 80px; height: 80px; margin-bottom: 20px; margin-left: 8px"
              alt="abstract"
            />
            <img
              v-else
              src="http://pesmcopt.com/admin-media/images/default-logo.png"
              style="width: 80px; height: 80px; margin-bottom: 20px; margin-left: 8px"
              alt="abstract"
            />
            </section>
            <span
              class="icon-flag"
              :style="{ backgroundImage: `url(${flagImage})` }"
            >
            </span>
          </b-col>
          <b-col cols="12" class="tmpt-nama">
            <h5
              style="font-weight: bold; font-size: 16px;"
            >
              {{ profil[0].answer }} 
            </h5>
          </b-col>
        </b-row>
        <b-form-row v-if="!loading">
          <b-col>
            <b-row v-for="(item, i) in profil" :key="i">
              <b-col sm="4"><p style="font-weight: 600">{{ item.label }}</p></b-col>:
              <b-col sm="6">
                 <span v-if="i == 0">{{ item.answer }}</span>
                 <span v-if="i == 1">{{ item.answer }}</span>
                 <span v-if="i == 2">{{ item.answer }}</span>
                 <span v-if="i == 3">{{ item.answer }}</span>
                 <span v-if="i == 4">{{ item.answer }}</span>
                 <span v-if="i == 5">{{ item.answer }}</span>
                 <span v-if="i == 6">{{ item.answer }}</span>
                 <span v-if="i == 7">{{ item.answer }}</span>
                 <span v-if="i == 8">{{ item.answer }}</span>
                 <span v-if="i == 9">{{ item.answer }}</span>
                <span v-if="i == 10">{{item.answer | moment('DD - MM - YYYY')}}</span>
                <span v-if="i == 11">{{ item.answer }}</span>
                <span v-if="i == 12 && item.answer == true">{{ item.answer == true ? "Aktif" : "Tidak Aktif"}}</span>
                <span v-if="i == 12 && item.answer == false" style="color: red;">{{ item.answer == true ? "Aktif" : "Tidak Aktif"}}</span>
                <span v-if="i == 13" :style="profil[12].answer == true ? '' : 'color:red;'">{{item.answer | moment('DD - MM - YYYY')}}</span>
                <span v-if="i == 14" :style="profil[12].answer == true ? '' : 'color:red;'">{{item.answer | moment('DD - MM - YYYY')}}</span>
                
                  <ul class="ml-5" v-if="i == 15">
                    <li v-for="(lisPeng,index) in dataDetail.listPengurus" :key="index">
                      {{ lisPeng.nama_pengurus}}
                    </li>
                  </ul>
                <span v-if="i == 16">{{ item.answer }}</span>
                <ul class="ml-5" v-if="i == 17">
                    <li v-for="(bidKe,index) in dataDetail.dataOrmas.bidangKegiatans" :key="index">
                      {{ bidKe.masterJenisOrmas.nama_jenis_ormas}}
                    </li>
                  </ul>
                <span v-if="i == 18">{{ item.answer }}</span>
                
                
                
                </b-col>
              
            </b-row>
          </b-col>
          <b-col>
            <p style="text-align:center;"><b>Struktur Pengurus</b></p>
            <b-table-simple responsive >
              <b-thead class="table-head">
                <b-tr>
                  <b-th>No</b-th>
                  <b-th class="text-left">Nama</b-th>
                  <b-th class="text-left">Jabatan</b-th>
                </b-tr>
             
              </b-thead>
              <b-tbody class="table-content" v-if="dataDetail.pengurusInti.length > 0">
                <b-tr v-for="(item,index) in dataDetail.pengurusInti" :key="index">
                  <b-td>{{index + 1}}</b-td>
                  <b-td class="text-left">{{item.nama_pengurus}}</b-td>
                  <b-td class="text-left">{{item.masterJabatan.nama_jabatan}}</b-td>
                </b-tr>
              </b-tbody>
              <b-tbody class="table-content" v-if="dataDetail.pengurusInti.length == 0">
                <b-tr>
                  <b-td>-</b-td>
                  <b-td>-</b-td>
                  <b-td>-</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <!-- <p style="margin-top: 30px; text-align:center;" v-if="otonom"><b>Badan Otonom / Sayap Organisasi</b></p>
            <b-row v-if="otonom">
              <b-col sm="12">
                <b-table-simple responsive>
                  <b-thead class="table-head">
                    <b-tr>
                      <b-th>No.</b-th>
                      <b-th class="text-left">Nama Organisasi</b-th>
                      <b-th class="text-left">Nomor Registrasi</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody class="table-content">
                    <b-tr>
                      <b-td>01</b-td>
                      <b-td class="text-left">Ormas Test 1</b-td>
                      <b-td class="text-left">1021023010</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row> -->
          </b-col>
        </b-form-row>
        <div class="text-center">
          <button
            class="mt-16 text-center button-back"
            @click="$router.go(-1)"
          >
            Kembali
          </button>
        </div>
      </div>
    </div>
  <!-- </div> -->
</template>
<script>
  import { isValidDate, leapingDate } from '../../../../myUtils';

  const fiveYearsAgo = leapingDate('-5 years')
  // Get expired yesterday
  fiveYearsAgo.setDate(fiveYearsAgo.getDate() - 1);

  export default {
    name: "DetailFloat",
    props: [],
    data() {
      return {
        perPage: 10,
        currentPage: 1,
        totalItems: 0,
        items: [],
        fileURL: process.env.VUE_APP_APPLICATION_FILE_URL,
        profil: [{
            label: "Nama Ormas",
            answer: "-"
          }, {
            label: "Singkatan",
            answer: "-"
          }, 
          // {
          //   label: "Nomor SKT Aktif",
          //   answer: "-"
          // }, 
          {
            label: "Status Sekretariat",
            answer: "-"
          }, {
            label: "Alamat sekretariat",
            answer: "-"
          }, {
            label: "Kabupaten/kota",
            answer: "-"
          }, {
            label: "Propinsi",
            answer: "-"
          },
          // {
          //   label: "Nomor Telepon",
          //   answer: "-"
          // },
          {
            label: "Nomor FAX",
            answer: "-"
          }, {
            label: "Email",
            answer: "-"
          }, {
            label: "Nama Notaris",
            answer: "-"
          }, {
            label: "Nomor Akta",
            answer: "-"
          }, {
            label: "Tanggal Pendirian",
            answer: "-"
          }, 
          {
            label: "Nomor SKT",
            answer: "-"
          },
          {
            label: "Status SKT",
            answer: "-"
          },
          {
            label: "Tanggal Terbit SKT",
            answer: "-"
          },
          {
            label: "Masa Berlaku SKT",
            answer: "-"
          }, {
            label: "Daftar Pendiri Organisasi",
            answer: "-"
          }, {
            label: "Ciri khusus",
            answer: "-"
          }, {
            label: "Bidang Kegiatan",
            answer: "-"
          }, {
            label: "Wadah Berhimpun",
            answer: "-"
          },
        ],
        loading: false,
        dataDetail: {
          "dataOrmas": {
            "nama_ormas": "-",
            "nama_singkat": "-",
            "npwp": "-",
            "alamat_sekretariat": "-",
            "no_skt": "-",
            "tanggal_skt": "-",
            "tanggal_berdiri": "-",
            "email": "-",
            "no_telp": "-",
            "no_fax": "-",
            "masterLokasiSekretariat": {
              "nama_lokasi": "-",
            },
            "bidangKegiatans": [{
              "masterJenisOrmas": {
                "nama_jenis_ormas": "-",
              }
            }],
            "infoPendirian": {
              "namaNotaris": "-",
              "nomorAkta": "-",
              "tanggalAkta": "-",
            },
            "masterProvinsi": {
              "nama": "-",
            },
            "masterKabupatenKota": {
              "nama": "-",
            },
            "masterCiriKhusus": {
              "nama_ciri_khusus": "-",
            }
          },
          "pengurusInti": [{
            "nama_pengurus": "-",
            "masterJabatan": {
              "nama_jabatan": "-",
            }
          }],
          "listPengurus": [{
            "nama_pengurus": "-",
            "masterKategoriPengurus": {
              "nama_kategori_pengurus": "-",
            }
          }],
          "wadahBerhimpun": {
            "nama_ormas": "-",
          }
        },
      };
    },
    mounted() {
      this.fetchData()
    },
    computed: {
      rows() {
        return this.items.length;
      },
      flagImage() {
        return process.env.BASE_URL + "/media/svg/flags/226-united-states.svg";
      }
    },
    methods: {
      async fetchData() {
        this.getDetail()
        this.loading = true
      },
      async getDetail() {
        let params2 = {
          
          "include": ["infoPendirian","masterProvinsi", "masterKabupatenKota", "masterLokasiSekretariat", "masterCiriKhusus", {
            "relation": "bidangKegiatans",
            "scope": {
              "include": [{
                "relation": "masterJenisOrmas"
              }]
            }
          }]
        }
        this.items = await fetch(process.env.VUE_APP_APPLICATION_SIORMAS_URL + "/pencarian-data-ormas/" + this.$route.query.uid + "?filter=" + encodeURIComponent(JSON.stringify(params2))).then(res => {
          return res.json();
        }).then(items => items).catch(error => {
          // this.loading = false
          this.errorMessage = error;
        });
        if (this.items.data.dataOrmas) {
          this.dataDetail = this.items.data
          setTimeout(() => {
            this.loading = false

            const tanggalSkt = this.dataDetail.dataOrmas.tanggal_skt
            const tanggal_skt_end = new Date(this.dataDetail.dataOrmas.tanggal_skt)
              tanggal_skt_end.setFullYear(tanggal_skt_end.getFullYear() + 5)
            let statusSkt = true

            if (isValidDate(tanggalSkt)) {
              const dataDate = new Date(tanggalSkt)

              if (dataDate.getTime() < fiveYearsAgo.getTime()) {
                statusSkt = false
              }
            }

            this.profil[0].answer = this.dataDetail.dataOrmas.nama_ormas
            this.profil[1].answer = this.dataDetail.dataOrmas.nama_singkat
            // this.profil[2].answer = this.dataDetail.dataOrmas.no_skt
            this.profil[2].answer = this.dataDetail.dataOrmas.masterLokasiSekretariat ? this.dataDetail.dataOrmas.masterLokasiSekretariat.nama_lokasi : "-"
            this.profil[3].answer = this.dataDetail.dataOrmas.alamat_sekretariat
            this.profil[4].answer = this.dataDetail.dataOrmas.masterKabupatenKota ? this.dataDetail.dataOrmas.masterKabupatenKota.nama : "-"
            this.profil[5].answer = this.dataDetail.dataOrmas.masterProvinsi ? this.dataDetail.dataOrmas.masterProvinsi.nama : "-"
            // this.profil[7].answer = this.dataDetail.dataOrmas.no_telp
            this.profil[6].answer = this.dataDetail.dataOrmas.no_fax
            this.profil[7].answer = this.dataDetail.dataOrmas.email
            this.profil[8].answer = this.dataDetail.dataOrmas.infoPendirian ? this.dataDetail.dataOrmas.infoPendirian.namaNotaris : "-"
            this.profil[9].answer = this.dataDetail.dataOrmas.infoPendirian ? this.dataDetail.dataOrmas.infoPendirian.nomorAkta : "-"
            this.profil[10].answer = this.dataDetail.dataOrmas.infoPendirian ? this.dataDetail.dataOrmas.infoPendirian.tanggalPendirian : "-"
            this.profil[11].answer = this.dataDetail.dataOrmas.no_skt
            this.profil[12].answer = statusSkt
            this.profil[13].answer = this.dataDetail.dataOrmas.tanggal_skt
            this.profil[14].answer = tanggal_skt_end
            this.profil[15].answer = this.dataDetail.listPengurus ? this.dataDetail.listPengurus.nama_pengurus : null
            this.profil[16].answer = this.dataDetail.dataOrmas.masterCiriKhusus ? this.dataDetail.dataOrmas.masterCiriKhusus.nama_ciri_khusus : "-"
            this.profil[17].answer = this.dataDetail.dataOrmas.bidangKegiatans
            this.profil[18].answer = this.dataDetail.wadahBerhimpun ? this.dataDetail.wadahBerhimpun.afiliasiOrmas.nama_ormas : "-"
          }, 2000)
        } else {
          this.loading = false
        }
      },
      info(item, index, button) {
        this.infoModal.title = `${item.nama_ormas}`;
        this.$root.$emit("bv::show::modal", this.infoModal.id, button);
      },
      resetInfoModal() {
        this.infoModal.title = "";
        this.infoModal.content = "";
      }
    },
    watch: {
      currentPage: {
        handler: function() {
          this.fetchData();
        }
      }
    }
  };
</script>
<style>
















  .table-head {
    text-align: center;
    background: #053a69;
    color: #ffffff;
    border-radius: 15px 15px 0 0;
  }

  .table-content {
    background: #fafcff;
    text-align: center;
    border-radius: 0 0 15px 15px;
  }

  .table-custom {
    border-radius: 15px 15px 15px 15px !important;
  }

  .form-custom {
    width: 434px;
    height: 59px;
    left: 102px;
    top: 232px;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 15px;
    margin-bottom: 40px;
    padding-left: 15px;
    background: #ffffff;
  }

  .btn-cari {
    background: #053a69;
    box-shadow: 0 4px 15px rgba(5, 58, 105, 0.25);
    border-radius: 10px;
    padding: 8px 18px;
  }
    @media screen and (min-device-width: 240px) and (min-width: 240px) and (max-device-width: 811px) and (max-width: 811px) and (-webkit-min-device-pixel-ratio: 2) {
        .tmpt-lb{
justify-content: center;
  display: flex !important;
        }
        .tmpt-nama{
          justify-content: center;
            display: flex;
        }
        .form-row {
              display: contents;
        }
        .row{
          margin-right: unset !important;
          margin-left: unset !important;
          
        }
    }
         @media screen and (min-device-width: 437px) and (min-width: 437px) and (max-device-width: 811px) and (max-width: 811px) and (-webkit-min-device-pixel-ratio: 2) {
                .col-sm-4 {
                  flex: unset !important;
                  max-width: 40% !important;
                  padding-right: unset !important;
                    padding-left: unset !important;
                    /* font-size: 8px !important; */
                }
                                .col-sm-6 {
                                  margin-left: 1%;
                                  flex: unset !important;
                                  max-width: 58% !important;
                                  padding-right: unset !important;
                                    padding-left: unset !important;
                                    /* font-size: 8px !important; */
                                }
    }
        @media screen and (min-device-width: 240px) and (min-width: 240px) and (max-device-width: 437px) and (max-width: 437px) and (-webkit-min-device-pixel-ratio: 2) {
          .col-sm-4 {
            flex: unset !important;
            max-width: 39% !important;
            padding-right: unset !important;
            padding-left: unset !important;
            font-size: 7px !important;
          }
    
          .col-sm-6 {
            margin-left: 1%;
            flex: unset !important;
            max-width: 58% !important;
            padding-right: unset !important;
            padding-left: unset !important;
            font-size: 7px !important;
          }
        }
</style>